import React from 'react';
import { observer } from 'mobx-react';
import { AutoSizer, Grid, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import CustomerCard from './CustomerCard';

const CustomersList = observer(({ customers }) => {
    const cardWidth = 300; // רוחב הכרטיסים בפיקסלים

    // יצירת cache לגובה דינמי של התאים
    const cache = new CellMeasurerCache({
        defaultHeight: 150,
        fixedWidth: true,
    });

    const cellRenderer = ({ columnIndex, key, rowIndex, style, parent }) => {
        const index = rowIndex * Math.floor(parent.props.width / cardWidth) + columnIndex;
        if (index >= customers.length) {
            return null;
        }

        const item = customers[index];

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                columnIndex={columnIndex}
                rowIndex={rowIndex}
                parent={parent}
            >
                <div style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomerCard customer={item} />
                </div>
            </CellMeasurer>
        );
    };

    return (
        <AutoSizer key={`${customers.length}`}>
            {({ width, height }) => {
                const columnCount = Math.floor(width / cardWidth);
                return (
                    <Grid
                        width={width}
                        height={height}
                        columnCount={columnCount}
                        columnWidth={cardWidth}
                        rowCount={Math.ceil(customers.length / columnCount)}
                        rowHeight={cache.rowHeight}
                        cellRenderer={cellRenderer}
                        deferredMeasurementCache={cache}
                        scrollToRow={0}
                    />
                );
            }}
        </AutoSizer>
    );
});

export default CustomersList;
