import React from 'react';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';
import dictionary from '../../../Mobx/Dictionary';
import './DeleteDictionaryEntry.css'; // Import the CSS file
import { convertMessageToHTML } from '../../../Utils/convertMessageToHTML';
import dictionaryLog from '../../../Mobx/DictionaryLog';
import { containsErrorKey } from '../../../Utils/error';

const DeleteDictionaryEntry = observer(({ entryId }) => {

    const handleDelete = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        const value = entryId ? dictionary.getOne(entryId) : null;

        const result = await Swal.fire({
            title: `האם למחוק את הערך \n${value.original} - ${value.trns}\n?`,
            text: `לא ניתן לבטל פעולה זו`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'מחק',
            cancelButtonText: 'ביטול'
        });

        if (result.isConfirmed) {
            try {
                const message = await dictionary.delete(entryId);
                // Swal.fire({
                //     title: 'Deleted!',
                //     // text: message,
                //     html: convertMessageToHTML(message),
                //     icon: 'success',
                //     confirmButtonText: 'OK',
                //     width: 'auto'
                // });
            } catch (error) {
                // const log = error.response.data;
                // dictionaryLog.addLog(log);
                // if (containsErrorKey(log))
                //     dictionary.setIsLoading('error');
                // Swal.fire({
                //     title: 'Error!',
                //     // text: `Failed to delete entry. Error: ${error.message}.\nError description: ${JSON.stringify(error.response.data).replace(/&quot;/g, '"')}`,
                //     html: convertMessageToHTML({ Error: error.message, description: error.response.data }),
                //     icon: 'error',
                //     confirmButtonText: 'OK',
                //     width: 'auto'
                // });
            }
        }
    };

    return (
        <button
            type="button"
            className="delete-dictionary-entry-button"
            onClick={handleDelete}
            disabled={!entryId}
        >
            מחק
        </button>
    );
});

export default DeleteDictionaryEntry;
