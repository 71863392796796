import axios from 'axios';
import urls from './Urls';
import { action, makeObservable, observable, runInAction } from "mobx";

class Customers {
    customers = [];
    customer_fields = [
        '_id',
        'firstName',
        'lastName',
        'email',
        'password',
        'role',
        'createdAt',
        'typeOfDictionary',
        'googleId',
        // 'facebookId',
        // 'appleId',
        'subscriptionLevel',
        'profilePicture',
        'serial'
    ];

    constructor() {
        makeObservable(this, {
            customers: observable,
            customer_fields: observable,
            getCustomers: action,
            clear: action
        });
    }

    async getCustomers() {
        // if (this.customers.length === 0) {
            const response = await axios.get(urls.user.getAll, {
                withCredentials: true
            });
            runInAction(() => {
                let data = response.data;
                data.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
                this.customers = data;
            });
        // }
    }

    clear() {
        this.customers = [];
    }
}

const customers = new Customers();
export default customers;
