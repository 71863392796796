import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material'; // Import MUI Typography
import dictionary from '../../../Mobx/Dictionary';
import './NumberOfResults.css';

const NumberOfResults = observer(() => {
    const formattedNumber = dictionary.current.sum.toLocaleString();

    return (
        <Typography className="number-of-results" variant="h6">
            {formattedNumber}
        </Typography>
    );
});

export default NumberOfResults;
