import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const ExportToExcel = observer(() => {
    const exportToExcel = () => {
        const data = dictionary.current.data;
        if (!data) {
            alert('No data to export.');
            return;
        }

        const fieldsOrder = dictionary.current.fields;
        const dictionaryType = dictionary.current.dictionaryType;

        // Reorder the data according to fieldsOrder
        const orderedData = data.map(entry => {
            const orderedEntry = {};
            fieldsOrder.forEach(field => {
                if (entry.hasOwnProperty(field)) {
                    // Handle the categories field
                    if (field === 'categories' && Array.isArray(entry[field])) {
                        // Convert the array of category objects to a string of category names, separated by commas.
                        orderedEntry[field] = entry[field].map(category => category.name).join(', ');
                    } else {
                        orderedEntry[field] = entry[field];
                    }
                } else {
                    orderedEntry[field] = ''; // Ensuring every field exists, even if it's empty
                }
            });
            return orderedEntry;
        });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert JSON data to a worksheet with the correct header order
        const worksheet = XLSX.utils.json_to_sheet(orderedData, { header: fieldsOrder });

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dictionary");

        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${dictionaryType}.xlsx`);
    };

    const getDictionaryName = (type) => {
        switch (type) {
            case 'dictionary_eng_to_heb': return 'Export English-Dictionary to Excel';
            case 'dictionary_heb_to_eng': return 'Export Hebrew-Dictionary to Excel';
            default: return 'Dictionary';
        }
    };

    return (
        <Stack direction="column" alignItems="center" spacing={2} className="export-to-excel">
            {/* <Typography variant="h6" component="div" gutterBottom>
                {getDictionaryName(dictionary.current.dictionaryType)}
            </Typography> */}
            <Button 
                variant="contained" 
                color="primary" 
                onClick={exportToExcel}
                size="large"
                className="export-button"
            >
                ייצוא לאקסל
            </Button>
        </Stack>
    );
});

export default ExportToExcel;
