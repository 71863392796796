import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AutoSizer, List } from 'react-virtualized';
import dictionary from '../../../Mobx/Dictionary';
import DictionaryCard from './DictionaryCard';

const DictionaryList = observer(({ selectedFields, textSize }) => {
    // _________________________________________________________________________________________
    const data = dictionary.current.data;
    const currentIndex = dictionary.current.value.index;

    const rowRenderer = ({ key, index, style }) => {
        const item = data[index];
        const isSelected = index === currentIndex;

        return (
            <div key={key} style={style} className="list-item">
                <DictionaryCard
                    entry={item}
                    selectedFields={selectedFields}
                    textSize={textSize}
                    isSelected={isSelected} />
            </div>
        );
    };

    // _________________________________________________________________________________________
    const [rowHeight, setRowHeight] = useState(0);

    useEffect(() => {
        const firstCard = document.querySelector('.dictionary-card');
        if (firstCard) {
            const height = firstCard.offsetHeight; // or use firstCard.getBoundingClientRect().height
            setRowHeight(height);
        }
    }, [selectedFields]);

    setTimeout(() => {
        const firstCard = document.querySelector('.dictionary-card');
        if (firstCard) {
            const height = firstCard.offsetHeight; // or use firstCard.getBoundingClientRect().height
            setRowHeight(height);
        }
    }, 100);

    // _________________________________________________________________________________________
    return (
        rowHeight === 0 ? rowRenderer({ key: '0', index: 0, style: {} }) : // כדי לחשב את rowHeight
            <AutoSizer
                key={ // This is to force the list to re-render when the rowHeight changes or the dictionary type changes
                    rowHeight +
                    dictionary.current.dictionaryType +
                    dictionary.current.sort?.field +
                    dictionary.current.sort?.order +
                    dictionary.current.data.length
                }
            >
                {({ width, height }) => (
                    <List
                        width={width}
                        height={height}
                        rowCount={data.length}
                        rowHeight={rowHeight + 5}
                        rowRenderer={rowRenderer}
                        scrollToIndex={currentIndex}
                    />
                )}
            </AutoSizer>
    );
});

export default DictionaryList;
