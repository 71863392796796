import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import groups from '../../Mobx/Groups';
// import './Categories.css';
import Layout from '../Layout/Layout/Layout';

const Categories = observer(() => {
    const [newCategoryName, setNewCategoryName] = useState('');
    const [selectedDictionaryType, setSelectedDictionaryType] = useState('dictionary_eng_to_heb');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingName, setEditingName] = useState('');

    useEffect(() => {
        groups.getCategories();
    }, []);

    const handleCreateCategory = async () => {
        if (newCategoryName.trim()) {
            const category = {
                name: newCategoryName,
                dictionaryType: selectedDictionaryType
            };
            await groups.createCategory(category);
            setNewCategoryName('');
        }
    };

    const handleUpdateCategory = async (category) => {
        await groups.updateCategory({ ...category, name: editingName });
        setEditingCategory(null);
        setEditingName('');
    };

    const handleDeleteCategory = async (category) => {
        await groups.deleteCategory(category);
    };

    // _____________________________________________________________________
    const tools = [];
    const drawerTools = [];

    return (
        <div className='Categories'>
            <Layout tools={tools} drawerTools={drawerTools}>
                <div className="form-container">
                    <label>
                        Dictionary&nbsp;
                        <select
                            value={selectedDictionaryType}
                            onChange={(e) => setSelectedDictionaryType(e.target.value)}
                        >
                            <option value="dictionary_eng_to_heb">English to Hebrew</option>
                            <option value="dictionary_heb_to_eng">Hebrew to English</option>
                        </select>
                    </label>

                    <input
                        type="text"
                        placeholder="Category name"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                    <button onClick={handleCreateCategory}>Create Category</button>
                </div>

                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Sum</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.categories[selectedDictionaryType].map((category) => (
                                <tr key={category._id}>
                                    <td>
                                        {editingCategory === category._id ? (
                                            <input
                                                type="text"
                                                dir="auto"
                                                value={editingName}
                                                onChange={(e) => setEditingName(e.target.value)}
                                            />
                                        ) : (
                                            category.name
                                        )}
                                    </td>
                                    <td>{category.sum}</td>
                                    <td>
                                        {editingCategory === category._id ? (
                                            <>
                                                <button onClick={() => handleUpdateCategory(category)}>Save</button>
                                                <button onClick={() => setEditingCategory(null)}>Cancel</button>
                                            </>
                                        ) : (
                                            <>
                                                <button onClick={() => {
                                                    setEditingCategory(category._id);
                                                    setEditingName(category.name);
                                                }}>
                                                    Edit
                                                </button>
                                                {category.sum === 0 && (
                                                    <button onClick={() => handleDeleteCategory(category)}>Delete</button>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Layout>
        </div>
    );
});

export default Categories;
