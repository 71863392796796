import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardContent, Typography, Button, CardActions, TextField, Box } from '@mui/material';
import groups from '../../Mobx/Groups';

const CategoryCard = observer(({ category }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(category.name);

    const handleUpdate = () => {
        groups.updateCategory({ ...category, name: newName });
        setIsEditing(false);
    };

    const handleDelete = () => {
        groups.deleteCategory(category);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setNewName(category.name); // חזרה לשם המקורי
        setIsEditing(false);
    };

    return (
        <Card sx={{ margin: 0.5, padding: 1, boxShadow: 3, width: 300, height: 180 }} className='category-card'>
            <CardContent>
                {isEditing ? (
                    <TextField
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label="Category Name"
                    />
                ) : (
                    <Typography variant="h6" component="div">
                        {category.name}
                    </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                    כמות: {category.sum}
                </Typography>
            </CardContent>
            <CardActions>
                {isEditing ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button size="small" color="primary" variant="contained" onClick={handleUpdate}>
                            שמור
                        </Button>
                        <Button size="small" color="secondary" variant="contained" onClick={handleCancel}>
                            ביטול
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button size="small" color="primary" variant="contained" onClick={handleEditClick}>
                            ערוך
                        </Button>
                        {category.sum === 0 && (
                            <Button size="small" color="error" variant="contained" onClick={handleDelete}>
                                מחק
                            </Button>
                        )}
                    </Box>
                )}
            </CardActions>
        </Card>
    );
});

export default CategoryCard;
