import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const KeyboardShortcuts = () => {
    return (
        <Box sx={{ padding: 1, backgroundColor: '#f9f9f9', borderRadius: 1, maxWidth: 300 }}>
            <Typography variant="h7" sx={{ marginBottom: 0.5 }}>
                קיצורי מקלדת
            </Typography>
            <List dense sx={{ padding: 0 }}>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemText
                        primary="מקש Esc"
                        secondary="ניקוי תיבת החיפוש."
                    />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemText
                        primary="צירוף מקשים Ctrl + Alt"
                        secondary="החלפת המילון."
                    />
                </ListItem>
            </List>
        </Box>
    );
};

export default KeyboardShortcuts;
