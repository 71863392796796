import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import user from '../../Mobx/User';
import axios from 'axios';
import urls from '../../Mobx/Urls';
import ReCAPTCHA from 'react-google-recaptcha';
import LoginWhiteOAuth from './LoginWhiteOAuth';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material'; // Import MUI components

const Login = observer(() => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if(data) setLoading(true);
        setError('');

        try {
            const message = await user.login(data?.email, data?.password);
            setLoading(false);
            setError(message);

            if (!message) { // Login was successful
                navigate('/dictionary');
            }
        } catch (err) {
            console.log('Error logging in:', err);
            if (err.response.status === 400)
                setError('אימייל או סיסמה שגויים');
            // setError(err.response?.data || 'An error occurred');
            setLoading(false);
            if (err.response?.status === 429) {
                setShowCaptcha(true);
            } else {
                setShowCaptcha(false);
                if (!err.response.data === 'No Token') setError(err.response.data);
                // setError(err.response.data);
            }
        }
    };

    // Login with OAuth 2.0 - using Google as an example
    useEffect(() => {
        onSubmit();
    }, []);

    useEffect(() => {
        if (user.name) { // Assuming if user.name exists, user is logged in
            navigate('/dictionary'); // Change URL to /dashboard or any other route
        }
    }, [user.name, navigate]);

    const onCaptchaChange = async (token) => {
        try {
            const response = await axios.post(urls.user.verifyCaptcha, { token });

            if (response.status === 200) {
                setCaptchaToken(token);
            } else if (response.status === 403) {
                setCaptchaToken('');
            }
        } catch (error) {
            console.error('Error verifying captcha:', error);
            setCaptchaToken('');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh', padding: 2 }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                {/* <Typography variant="h5" component="h1" align="center" gutterBottom>
                    Login
                </Typography> */}

                <TextField
                    label="אימייל"
                    type="email"
                    {...register('email', { required: 'אימייל נדרש' })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                />

                <TextField
                    label="סיסמה"
                    type="password"
                    {...register('password', { required: 'סיסמה נדרשת' })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    fullWidth
                />

                {showCaptcha && (
                    <ReCAPTCHA
                        sitekey="6LcjOnAqAAAAADYmBWd90exNIRvSLzUjx7_78Yzi"
                        onChange={onCaptchaChange}
                    />
                )}

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || (showCaptcha && !captchaToken)}
                    fullWidth
                >
                    {loading ? <CircularProgress size={24} /> : 'כניסה'}
                </Button>

                {error && <Alert severity="error">{error}</Alert>}
                <LoginWhiteOAuth />
            </Box>
        </Box>
    );
});

export default Login;
