import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import user from '../../Mobx/User';
import Button from '@mui/material/Button'; // Import MUI Button

const Logout = observer(() => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleLogout = () => {
        const message = user.logout();
        navigate('/login'); // Redirect to login after logout
    };

    return (
        <Button variant="text" color="inherit" onClick={handleLogout}>
            יציאה
        </Button>
    );
});

export default Logout;
