import React from 'react';
import Modal from 'react-modal';
import './Popup.css';

const Popup = ({ isOpen, onClose, title, children }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={title}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-header">
                <button onClick={onClose} className="close-button">X</button>
                <h2>{title}</h2>
            </div>
            <div className="modal-content">
                {children}
            </div>
        </Modal>
    );
};

export default Popup;
