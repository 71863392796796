import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import dictionary from '../../../Mobx/Dictionary';

const ColumnSelector = ({ selectedFields, setSelectedFields }) => {
    const allFields = dictionary.current.fields.map(field => ({
        // label: field.charAt(0).toUpperCase() + field.slice(1),
        label: field,
        value: field,
    }));

    const options = [{ label: 'בחר הכל', value: 'select_all' }, ...allFields];

    const handleChange = (event, value, reason) => {
        if (reason === 'selectOption' && value.some(item => item.value === 'select_all')) {
            if (selectedFields.length === allFields.length) {
                setSelectedFields([]);
            } else {
                setSelectedFields(allFields);
            }
        } else {
            setSelectedFields(value.filter(item => item.value !== 'select_all'));
        }
    };

    const isAllSelected = selectedFields.length === allFields.length;

    return (
        <div className="ColumnSelector">
            <Typography variant="h7" gutterBottom>
                מיון ובחירת עמודות
            </Typography>
            <Autocomplete
                multiple
                options={options}
                getOptionLabel={(option) => option.label}
                value={isAllSelected ? [{ label: 'בחר הכל', value: 'select_all' }, ...selectedFields] : selectedFields}
                onChange={handleChange}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                    <li
                        {...props}
                        style={{
                            textAlign: 'left',
                            direction: 'ltr',
                        }}
                    >
                        {/* <Checkbox
                            checked={selected || (option.value === 'select_all' && isAllSelected)}
                        /> */}
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="בחר עמודות"
                    />
                )}
                className="multi-select"
            />
        </div>
    );
};

export default ColumnSelector;
