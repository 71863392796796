import React from 'react';
import { Typography, Box } from '@mui/material';

const NoToolsMessage = () => (
    <Box sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant="body1" color="textSecondary">
            אין כלים נוספים
        </Typography>
    </Box>
);

export default NoToolsMessage;
