import React from 'react';
import Layout from '../Layout/Layout/Layout';
import { Box, Typography, Paper } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

const AI = () => {
    const tools = [];
    const drawerTools = [];

    return (
        <Layout tools={tools} drawerTools={drawerTools}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                marginTop={10}
                bgcolor="#f5f5f5"
                p={2}
            >
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        textAlign: 'center',
                        maxWidth: 400,
                        borderRadius: 2,
                    }}
                >
                    <BuildIcon
                        sx={{ fontSize: 50, color: '#1976d2', marginBottom: 2 }}
                    />
                    <Typography variant="h4" gutterBottom>
                        האזור בבניה
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        אנחנו עובדים קשה כדי להביא לך את התכונות הטובות ביותר.
                        חזור בקרוב!
                    </Typography>
                </Paper>
            </Box>
        </Layout>
    );
};

export default AI;
