import React from "react";
import { Button } from "@mui/material";
import axios from "axios";

const ReloadingRamMemory = () => {
    const handleReloadDictionaries = async () => {
        try {
            const isDevelopment = process.env.NODE_ENV === "development";

            const urls = isDevelopment
                ? [
                    "http://localhost:3001/api/dictionary/ram-memory/reloading-dictionaries",
                ]
                : [
                    // "https://test.lexillon.co.il/api/dictionary/ram-memory/reloading-dictionaries",
                    "https://main.lexillon.co.il/api/dictionary/ram-memory/reloading-dictionaries",
                ];

            // Execute all requests in parallel
            const responses = await Promise.all(
                urls.map((url) =>
                    axios.get(url, {
                        withCredentials: true,
                    })
                )
            );

            const allSuccessful = responses.every((response) => response.status === 200);

            if (allSuccessful) {
                alert("Dictionaries reloaded successfully on all servers!");
            } else {
                alert("Failed to reload dictionaries on one or more servers.");
            }
        } catch (error) {
            console.error("Error reloading dictionaries:", error);
            alert("An error occurred while reloading dictionaries.");
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleReloadDictionaries}
        >
            Reload Dictionaries
        </Button>
    );
};

export default ReloadingRamMemory;
