import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CustomersPage from '../Customers/CustomerPage';
import DictionaryPage from '../Dictionary/DictionaryPage';
import Login from '../User/Login';
import CategoriesPage from '../Categories/CategoryPage';
import AIPage from '../AI/AIPage';
import PaymentPage from '../TestPayment/PaymentPage';

function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dictionary" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dictionary" element={<DictionaryPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/ai" element={<AIPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/test-paypal" element={<PaymentPage />} />
        </Routes>
    );
}

export default Routing;
