import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, List, ListItem, ListItemText, LinearProgress } from '@mui/material';
import user from '../../../Mobx/User';
import dictionary from '../../../Mobx/Dictionary';
import groups from '../../../Mobx/Groups';
import dictionaryLog from '../../../Mobx/DictionaryLog';

const Loading = observer(() => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 4,
                backgroundColor: '#f9f9f9',
                height: '100vh',
                textAlign: 'left',
            }}
        >

            {/* Welcome Text */}
            <Typography
                variant="h4"
                sx={{
                    marginTop: 2,
                    marginBottom: 4,
                    textAlign: 'center', // יישור למרכז
                    color: 'text.primary', // צבע טקסט ראשי מה-theme
                    fontWeight: 500, // משקל טקסט בינוני
                    fontFamily: 'inherit', // שימוש בפונט שמוגדר כברירת מחדל
                    backgroundColor: 'rgba(0, 0, 0, 0.05)', // צבע רקע עדין
                    px: 3,
                    py: 1,
                    borderRadius: 1, // פינות מעוגלות קלות
                }}
            >
                שלום {user.name} !
            </Typography>

            {/* Progress List */}
            <List sx={{ width: '100%', maxWidth: 500 }}>
                <ListItem>
                    <ListItemText
                        primary="קטגוריות"
                        secondary={`${groups.isLoading.loaded} / ${groups.isLoading.total} MB`}
                    />
                    <LinearProgress
                        variant="determinate"
                        value={groups.isLoading.total
                            ? (groups.isLoading.loaded / groups.isLoading.total) * 100
                            : 0 // קו התקדמות ריק בהתחלה
                        }
                        sx={{
                            width: '65%',
                            marginLeft: 2,
                            height: 20,
                            borderRadius: 1,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="לוגים של המילונים"
                        secondary={`${dictionaryLog.isLoading.loaded} / ${dictionaryLog.isLoading.total} MB`}
                    />
                    <LinearProgress
                        variant="determinate"
                        value={
                            dictionaryLog.isLoading?.total
                                ? (dictionaryLog.isLoading.loaded / dictionaryLog.isLoading.total) * 100
                                : 0 // קו התקדמות ריק בהתחלה
                        }
                        sx={{
                            width: '65%',
                            marginLeft: 2,
                            height: 20,
                            borderRadius: 1,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="מילון אנגלי-עברי"
                        secondary={`${dictionary.isLoading.dictionary_eng_to_heb.loaded} / ${dictionary.isLoading.dictionary_eng_to_heb.total} MB`}
                    />
                    <LinearProgress
                        variant="determinate"
                        value={dictionary.isLoading.dictionary_eng_to_heb.total
                            ? (dictionary.isLoading.dictionary_eng_to_heb.loaded / dictionary.isLoading.dictionary_eng_to_heb.total) * 100
                            : 0 // קו התקדמות ריק בהתחלה
                        }
                        sx={{
                            width: '65%',
                            marginLeft: 2,
                            height: 20,
                            borderRadius: 1,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="מילון עברי-אנגלי"
                        secondary={`${dictionary.isLoading.dictionary_heb_to_eng.loaded} / ${dictionary.isLoading.dictionary_heb_to_eng.total} MB`}
                    />
                    <LinearProgress
                        variant="determinate"
                        value={dictionary.isLoading.dictionary_heb_to_eng.total
                            ? (dictionary.isLoading.dictionary_heb_to_eng.loaded / dictionary.isLoading.dictionary_heb_to_eng.total) * 100
                            : 0 // קו התקדמות ריק בהתחלה
                        }
                        sx={{
                            width: '65%',
                            marginLeft: 2,
                            height: 20,
                            borderRadius: 1,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="ממשק משתמש"
                        secondary={dictionary.isLoading.all === true
                            ? ''
                            : dictionary.isLoading.all > 1
                                ? `${dictionary.isLoading.all}`
                                : 'בהצלחה 🙂'}
                    />
                </ListItem>
            </List>
        </Box>
    );
});

export default Loading;
