import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CustomersPage from '../../Customers/CustomerPage';
import DictionaryPage from '../../Dictionary/DictionaryPage/DictionaryPage';
import Login from '../../User/Login/Login';
import CategoriesPage from '../../Categories/CategoryPage';
import AI from '../../AI/AI';
import TestPayPal from '../../TestPayPal/TestPayPal';

function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dictionary" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dictionary" element={<DictionaryPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/ai" element={<AI />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/test-paypal" element={<TestPayPal />} />
        </Routes>
    );
}

export default Routing;
