import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dictionary from '../../../Mobx/Dictionary';
import {
    Box,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Select,
} from '@mui/material';
import './SearchForm.css';

const SearchForm = observer(() => {
    const search = dictionary.search;

    const [text, setText] = useState(search.text);
    const [field, setField] = useState(search.field);
    const [debouncedText, setDebouncedText] = useState(text);

    const fields = dictionary.current.fields;

    useEffect(() => {
        const handler = setTimeout(() => {
            dictionary.setCurrent(null, { text: debouncedText, field }, null, -1);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedText, field]);

    const handleTextChange = (e) => {
        handleInputChange(e); // קריאה לפונקציה שמקבעת כיוון טקסט
        setText(e.target.value);
        setDebouncedText(e.target.value);
    };

    const handleFieldChange = (e) => {
        setField(e.target.value);
        dictionary.setCurrent(null, { text: debouncedText, field: e.target.value }, null, -1);
    };

    const handleReset = () => {
        setText('');
        setField('all');
        setDebouncedText('');
        dictionary.setCurrent(null, { text: '', field: 'all' }, null, -1);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') handleReset();
    };

    // _________________________________________________________
    // פונקציה שמקבעת כיוון טקסט
    const [direction, setDirection] = useState('ltr'); // ברירת מחדל

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value) {
            // קביעת כיוון אוטומטית לפי התו הראשון
            const firstChar = value.trim()[0];
            setDirection(/[\u0590-\u05FF]/.test(firstChar) ? 'ltr' : 'rtl');
        } else {
            setDirection('ltr'); // ברירת מחדל כשהשדה ריק
        }
    };
    // _________________________________________________________
    return (
        <Box
            component="div"
            onKeyDown={handleKeyDown}
            className="search-form-container"
        >
            <TextField
                id="searchText"
                // label="חפש במילון"
                sx={{
                    '& input': {
                        direction: direction, // כיוון דינמי
                    },
                }}
                variant="outlined"
                value={text}
                onChange={handleTextChange}
                placeholder="חפש במילון"
                autoComplete="off"
                className="search-text-field"
            />
            <FormControl className="search-form-select" sx={{ minWidth: '150px' }}>
                <InputLabel id="searchField-label"></InputLabel>
                <Select
                    id="searchField"
                    labelId="searchField-label"
                    value={field}
                    onChange={handleFieldChange}
                    sx={{ height: '34px' }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                textAlign: 'right', // מיישר את כל הטקסט לשמאל
                                direction: 'rtl', // מבטיח כיוון משמאל לימין
                            },
                        },
                    }}
                >
                    <MenuItem value="all">בכל השדות</MenuItem>
                    {fields.map((fieldOption) =>
                        fieldOption === '_id' ? null : (
                            <MenuItem key={fieldOption} value={fieldOption}>
                                {fieldOption}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
                className="search-form-button"
            >
                נקה
            </Button>
        </Box>
    );
});

export default SearchForm;
