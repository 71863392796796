import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import Layout from '../Layout/Layout';
import CreateSubscription from './CreateSubscription';
import CancelSubscription from './CancelSubscription';

const PaymentPage = () => {

    const tools = [];
    const drawerTools = [];

    return (
        <Layout tools={tools} drawerTools={drawerTools}>
            <Container maxWidth="md">
                <Box sx={{ py: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <CancelSubscription />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CreateSubscription />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Layout>
    );
};

export default PaymentPage;
