import "./CancelSubscription.css";
import { useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2'; // npm install sweetalert2
import urls from "../../Mobx/Urls"; // Import the URLs
import customers from "../../Mobx/Customers";

function CancelSubscription() {

    async function cancelSubscription() {
        const response = await axios.post(`${urls.payment.cancelSubscription}`, {}, { withCredentials: true });
        return response.status;
    }

    const [loading, setLoading] = useState(false);

    async function handleCancelSubscription() {
        try {
            setLoading(true);

            const status = await cancelSubscription();

            if (status === 204) {
                Swal.fire({
                    title: 'Subscription Canceled Successfully!',
                    text: 'We’re sorry to see you go and hope to welcome you back soon. If there’s anything we can improve, please let us know.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    width: '700'
                });
            }
            setLoading(false);
        }
        catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `Failed to cancel the subscription - ${error.message}. ${error.response.data || ""}`,
                icon: 'error',
                confirmButtonText: 'OK',
                width: '700'
            });
            console.error(error);
            setLoading(false);
        }
        finally {
            customers.getCustomers();
        }
    }

    return (
        <div className="CancelSubscription">
            <button onClick={handleCancelSubscription}>
                {loading ? 'Loading...' : 'Cancel Subscription'}
            </button>
        </div>
    );
}

export default CancelSubscription;
