import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import user from '../../Mobx/User';

const UserNameDisplay = observer(() => {
    return (
        <Typography variant="h6">
            {user.name ? user.name : 'Please log in.'}
        </Typography>
    );
});

export default UserNameDisplay;
